export const ORDER_TYPES = {
  NOTA_FISCAL: 'N',
  PEDIDO: 'P',
  MKT_SAC: 'A',
  VENDA_FUTURA: 'M',
}

export const ORDER_TYPES_LABELS = {
  N: 'NOTA FISCAL',
  P: 'PEDIDO',
  A: 'MKT SAC',
  M: 'VENDA FUTURA',
  B: 'BONIFICAÇÃO',
}

export const ORDER_TYPES_ACRONYM = {
  N: 'NF',
  P: 'PE',
  A: 'MKT/SAC',
  M: 'VF',
  B: 'BN',
}

// ----------------------------------------------------------

export const isSelectableOrderItem = (type) =>
  [ORDER_TYPES.VENDA_FUTURA].includes(type)

export const isEditableOrderItem = (type) =>
  [ORDER_TYPES.VENDA_FUTURA].includes(type)
